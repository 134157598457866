// components/MainPage.js
import React from "react";
import { Link } from "react-router-dom";

function MainPage() {
  return (
    <div className="text-center">
      <h1 className="text-4xl font-bold mb-8">
        Welcome to Ethereum Contract Manager
      </h1>
      <div className="space-y-4">
        <Link
          to="/launcher"
          className="block w-64 mx-auto bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          Launcher
        </Link>
        <Link
          to="/key-management"
          className="block w-64 mx-auto bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
        >
          Key Management
        </Link>
        <Link
          to="/project-management"
          className="block w-64 mx-auto bg-purple-500 text-white py-2 px-4 rounded hover:bg-purple-600"
        >
          Project Management
        </Link>
      </div>
    </div>
  );
}

export default MainPage;
