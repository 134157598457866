import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

const DEFAULT_TAX_SETTINGS = {
  startBuyTax: "20",
  startSellTax: "20",
  endBuyTax: "0",
  endSellTax: "0",
  numTransactions: "25",
};

function Launcher() {
  const [formData, setFormData] = useState({
    tickerName: "",
    coinName: "",
    ...DEFAULT_TAX_SETTINGS,
    selectedKeyId: "",
  });
  const [keys, setKeys] = useState([]);
  const [isLaunching, setIsLaunching] = useState(false);
  const [launchStatus, setLaunchStatus] = useState([]);
  const cliRef = useRef(null);

  useEffect(() => {
    fetchKeys();
  }, []);

  useEffect(() => {
    if (cliRef.current) {
      cliRef.current.scrollTop = cliRef.current.scrollHeight;
    }
  }, [launchStatus]);

  const fetchKeys = async () => {
    try {
      const response = await axios.get("/api/keys");
      setKeys(response.data.filter((key) => !key.used));
    } catch (error) {
      console.error("Error fetching keys:", error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLaunching(true);
    setLaunchStatus([]);

    const eventSource = new EventSource(
      "/api/launch-status"
    );
    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setLaunchStatus((prevStatus) => [...prevStatus, data.status]);
      if (data.status.includes("completed successfully") || data.error) {
        eventSource.close();
        setIsLaunching(false);
      }
    };

    try {
      const response = await axios.post(
        "/api/launch",
        formData
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error launching contract:", error);
      setLaunchStatus((prevStatus) => [
        ...prevStatus,
        "Error launching contract",
      ]);
    }
  };

  const resetToDefaults = () => {
    setFormData((prevData) => ({
      ...prevData,
      ...DEFAULT_TAX_SETTINGS,
    }));
  };

  return (
    <div className="max-w-2xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">
        Ethereum Smart Contract Launcher
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="tickerName" className="block mb-1">
            Ticker Name:
          </label>
          <input
            type="text"
            id="tickerName"
            name="tickerName"
            value={formData.tickerName}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label htmlFor="coinName" className="block mb-1">
            Coin Name:
          </label>
          <input
            type="text"
            id="coinName"
            name="coinName"
            value={formData.coinName}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="startBuyTax" className="block mb-1">
              Start Buy Tax (%):
            </label>
            <input
              type="number"
              id="startBuyTax"
              name="startBuyTax"
              value={formData.startBuyTax}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div>
            <label htmlFor="startSellTax" className="block mb-1">
              Start Sell Tax (%):
            </label>
            <input
              type="number"
              id="startSellTax"
              name="startSellTax"
              value={formData.startSellTax}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div>
            <label htmlFor="endBuyTax" className="block mb-1">
              End Buy Tax (%):
            </label>
            <input
              type="number"
              id="endBuyTax"
              name="endBuyTax"
              value={formData.endBuyTax}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div>
            <label htmlFor="endSellTax" className="block mb-1">
              End Sell Tax (%):
            </label>
            <input
              type="number"
              id="endSellTax"
              name="endSellTax"
              value={formData.endSellTax}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
        </div>
        <div>
          <label htmlFor="numTransactions" className="block mb-1">
            Number of Transactions:
          </label>
          <input
            type="number"
            id="numTransactions"
            name="numTransactions"
            value={formData.numTransactions}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label htmlFor="contractComments" className="block mb-1">
            Contract Comments:
          </label>
          <textarea
            id="contractComments"
            name="contractComments"
            value={formData.contractComments}
            onChange={handleChange}
            className="w-full p-2 border rounded font-mono whitespace-pre-wrap"
            rows="6"
            placeholder="Enter comments to appear at the top of the contract."
          />
        </div>
        <div>
          <label htmlFor="selectedKeyId" className="block mb-1">
            Select Key:
          </label>
          <select
            id="selectedKeyId"
            name="selectedKeyId"
            value={formData.selectedKeyId}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
          >
            <option value="">Select a key</option>
            {keys.map((key) => (
              <option key={key.id} value={key.id}>
                {key.private_key} - {key.balance}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-between">
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            disabled={isLaunching}
          >
            {isLaunching ? "Launching..." : "Launch Contract"}
          </button>
          <button
            type="button"
            onClick={resetToDefaults}
            className="bg-gray-300 text-gray-800 py-2 px-4 rounded hover:bg-gray-400"
            disabled={isLaunching}
          >
            Reset to Defaults
          </button>
        </div>
      </form>
      <div className="w">
        <h3 className="text-xl font-bold mb-2">Launch Progress</h3>
        <div
          ref={cliRef}
          className="bg-black text-green-400 p-4 rounded h-96 overflow-y-auto font-mono"
        >
          {launchStatus.map((status, index) => (
            <div key={index}>{`> ${status}`}</div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Launcher;
