import React, { useState, useEffect } from "react";
import axios from "axios";
import { ethers } from "ethers";
import PrivateKeyToggle from "./PrivateKeyToggle";

function KeyManagement() {
  const [keys, setKeys] = useState([]);
  const [newKey, setNewKey] = useState("");
  const [keyCount, setKeyCount] = useState(1);
  const [provider, setProvider] = useState(null);
  const [balances, setBalances] = useState({});
  const [filter, setFilter] = useState("all");

  useEffect(() => {
    fetchKeys();
    const customRPC =
      "https://mainnet.infura.io/v3/fc6cb6b83dd740a8bc81cbd9fd4235cb"; // Replace with your RPC URL
    setProvider(new ethers.JsonRpcProvider(customRPC));
  }, []);

  const refreshBalance = async (keyId, privateKey) => {
    try {
      const address = ethers.computeAddress(privateKey);
      const response = await axios.put(`/api/keys/${keyId}/refresh-balance`, { address });
      fetchKeys(); // Refresh the key list to get updated balances
    } catch (error) {
      console.error("Error refreshing balance:", error);
    }
  };

  const fetchBalances = async () => {
    const newBalances = {};
    for (const key of keys) {
      const address = ethers.computeAddress(key.private_key);
      try {
        const balance = await provider.getBalance(address);
        newBalances[address] = ethers.formatEther(balance);
      } catch (error) {
        console.error("Error fetching balance:", error);
        newBalances[address] = "Error";
      }
    }
    setBalances(newBalances);
  };

  const markKeyAsUsed = async (keyId) => {
    try {
      await axios.put(`/api/keys/${keyId}/mark-used`);
      fetchKeys(); // Refresh the key list to reflect the change
    } catch (error) {
      console.error("Error marking key as used:", error);
    }
  };

  const handleGenerateKeys = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `/api/generate-keys/${keyCount}`
      );
      console.log(response.data.message);
      fetchKeys(); // Refresh the key list
    } catch (error) {
      console.error("Error generating keys:", error);
    }
  };

  const fetchKeys = async () => {
    try {
      const response = await axios.get("/api/keys");
      setKeys(response.data);
    } catch (error) {
      console.error("Error fetching keys:", error);
    }
  };

  const handleAddKey = async (e) => {
    e.preventDefault();
    try {

      if (newKey.includes("0x")) {
        await axios.post("/api/keys", {
          private_key: newKey,
        });
        setNewKey("");
        fetchKeys();
      }
    } catch (error) {
      console.error("Error adding key:", error);
    }
  };

  const filteredKeys = keys.filter((key) => {
    if (filter === "used") return key.used;
    if (filter === "unused") return !key.used;
    return true; // "all" case
  });

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Key Management</h2>
      <div className="mb-4">
        <label>
          <input
            type="radio"
            value="all"
            checked={filter === "all"}
            onChange={() => setFilter("all")}
          />
          All
        </label>
        <label className="ml-4">
          <input
            type="radio"
            value="used"
            checked={filter === "used"}
            onChange={() => setFilter("used")}
          />
          Used
        </label>
        <label className="ml-4">
          <input
            type="radio"
            value="unused"
            checked={filter === "unused"}
            onChange={() => setFilter("unused")}
          />
          Unused
        </label>
      </div>
      <form onSubmit={handleAddKey} className="mb-4">
        <input
          type="text"
          value={newKey}
          onChange={(e) => setNewKey(e.target.value)}
          placeholder="Enter new private key with 0x"
          className="p-2 border rounded mr-2"
        />
        <button
          type="submit"
          className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
        >
          Add Key
        </button>
      </form>
      <form onSubmit={handleGenerateKeys} className="mb-4">
        <input
          type="number"
          value={keyCount}
          onChange={(e) => setKeyCount(Math.max(1, parseInt(e.target.value)))}
          min="1"
          className="p-2 border rounded mr-2 w-20"
        />
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          Generate Keys
        </button>
      </form>
      <ul className="space-y-2">
        {filteredKeys.map((key) => {
          const address = ethers.computeAddress(key.private_key);
          return (
            <li
              key={key.id}
              className="bg-white p-2 rounded shadow flex flex-col"
            >
              <div className="flex items-center mb-2">
                <span className="font-bold mr-2">Private Key:</span>
                <PrivateKeyToggle privateKey={key.private_key} />
              </div>
              <span>Public Address: {address} | <a href={`https://etherscan.io/address/${address}`}>Etherscan</a></span>
              <span>Balance: {key.balance || "Loading..."} ETH</span>
            <span>Used: {key.used ? "Yes" : "No"}</span>
            <button
              onClick={() => refreshBalance(key.id, key.private_key)}
                className="bg-yellow-500 text-white py-1 px-2 rounded hover:bg-yellow-600 mt-2"
              >
                Refresh Balance
              </button>
              <button
          onClick={() => markKeyAsUsed(key.id)}
          className="bg-red-500 text-white py-1 px-2 rounded hover:bg-red-600 mt-2"
        >
          Mark as Used
        </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default KeyManagement;
