import React, { useState, useEffect } from "react";
import axios from "axios";

function ProjectManagement() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await axios.get("/api/projects");
      setProjects(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
      setError("Failed to fetch projects");
    }
  };

  const burnLPTokens = async (projectId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `/api/burn-lp/${projectId}`
      );
      alert(response.data.message);
      // Optionally, refresh the project list after burning LP tokens
      await fetchProjects();
    } catch (error) {
      console.error("Error burning LP tokens:", error);
      setError("Failed to burn LP tokens");
    } finally {
      setLoading(false);
    }
  };

  const verifyContract = async (projectId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `/api/verify-contract/${projectId}`
      );
      alert(response.data.message);
    } catch (error) {
      console.error("Error verifying contract:", error);
      setError("Failed to verify contract");
    } finally {
      setLoading(false);
    }
  };

  const enableTrading = async (projectId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `/api/enable-trading/${projectId}`
      );
      alert(response.data.message);
      // Optionally, refresh the project list after enabling trading
      await fetchProjects();
    } catch (error) {
      console.error("Error enabling trading:", error);
      setError("Failed to enable trading");
    } finally {
      setLoading(false);
    }
  };

  const renounceOwnership = async (projectId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `/api/renounce-ownership/${projectId}`
      );
      alert(response.data.message);
      // Optionally, refresh the project list after renouncing ownership
      await fetchProjects();
    } catch (error) {
      console.error("Error renouncing ownership:", error);
      setError("Failed to renounce ownership");
    } finally {
      setLoading(false);
    }
  };

  const removeLimits = async (projectId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `/api/remove-limits/${projectId}`
      );
      alert(response.data.message);
      // Optionally, refresh the project list after removing limits
      await fetchProjects();
    } catch (error) {
      console.error("Error removing limits:", error);
      setError("Failed to remove limits");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Project Management</h2>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <table className="w-full border-collapse border">
        <thead>
          <tr className="bg-gray-200">
            <th className="border p-2">Coin Name</th>
            <th className="border p-2">Ticker</th>
            <th className="border p-2">Contract Address</th>
            <th className="border p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {projects.map((project) => (
            <tr key={project.id}>
              <td className="border p-2">{project.name}</td>
              <td className="border p-2">{project.ticker_name}</td>
              <td className="border p-2">{project.contract_address}</td>
              <td className="border p-2">
                <button
                  onClick={() => burnLPTokens(project.id)}
                  className="bg-red-500 text-white py-1 px-2 rounded mr-2 hover:bg-red-600"
                  disabled={loading}
                >
                  Burn LP
                </button>
                <button
                  onClick={() => verifyContract(project.id)}
                  className="bg-green-500 text-white py-1 px-2 rounded mr-2 hover:bg-green-600"
                  disabled={loading}
                >
                  Verify Contract
                </button>
                <button
                  onClick={() => enableTrading(project.id)}
                  className="bg-blue-500 text-white py-1 px-2 rounded mr-2 hover:bg-blue-600"
                  disabled={loading}
                >
                  Enable Trading
                </button>
                <button
                  onClick={() => renounceOwnership(project.id)}
                  className="bg-purple-500 text-white py-1 px-2 rounded mr-2 hover:bg-purple-600"
                  disabled={loading}
                >
                  Renounce Ownership
                </button>
                <button
                  onClick={() => removeLimits(project.id)}
                  className="bg-yellow-500 text-white py-1 px-2 rounded hover:bg-yellow-600"
                  disabled={loading}
                >
                  Remove Limits
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ProjectManagement;
