import React, { useState } from "react";

function PrivateKeyToggle({ privateKey }) {
  const [showKey, setShowKey] = useState(false);

  return (
    <div className="flex items-center">
      <span className="mr-2">
        {showKey ? privateKey : privateKey.substring(0, 6) + "..."}
      </span>
      <button
        onClick={() => setShowKey(!showKey)}
        className="bg-gray-200 hover:bg-gray-300 px-2 py-1 rounded text-sm"
      >
        {showKey ? "Hide" : "Show"}
      </button>
    </div>
  );
}

export default PrivateKeyToggle;
