// App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import MainPage from "./components/MainPage";
import Launcher from "./components/Launcher";
import KeyManagement from "./components/KeyManagement";
import ProjectManagement from "./components/ProjectManagement";
import axios from "axios";
import Login from "./components/Login";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if there's a token in localStorage
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common["Authorization"];
    setIsLoggedIn(false);
  };

  if (!isLoggedIn) {
    return <Login onLogin={handleLogin} />;
  }

  return (
    <Router>
      <div className="min-h-screen bg-gray-100">
        <nav className="bg-blue-600 p-4">
          <ul className="flex space-x-4">
            <li>
              <Link to="/" className="text-white hover:underline">
                Home
              </Link>
            </li>
            <li>
              <Link to="/launcher" className="text-white hover:underline">
                Launcher
              </Link>
            </li>
            <li>
              <Link to="/key-management" className="text-white hover:underline">
                Key Management
              </Link>
            </li>
            <li>
              <Link
                to="/project-management"
                className="text-white hover:underline"
              >
                Project Management
              </Link>
            </li>
          </ul>
        </nav>

        <div className="container mx-auto p-4">
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/launcher" element={<Launcher />} />
            <Route path="/key-management" element={<KeyManagement />} />
            <Route path="/project-management" element={<ProjectManagement />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
